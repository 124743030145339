<template>
  <div>
    <div class="container">
      <div class="row g-0" style="min-height: 65vh !important">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">My Schedule.</span>
            <button
              type="button"
              class="btn btn-primary me-2 mt-auto"
              data-bs-toggle="modal"
              data-bs-target="#schedule-filters"
            >
              Filters
            </button>
          </h1>
          <p v-if="!$store.state.user.reservations.length">
            Looks like you don't have access to any reservations! Contact your
            supervisor to get access.
          </p>
          <p v-if="unassignedReservations.length > 0">
            Uh, oh. There
            <span v-if="unassignedReservations.length > 1"
              >are {{ unassignedReservations.length }} reservations</span
            >
            <span v-else
              >is {{ unassignedReservations.length }} reservation</span
            >
            that
            <span v-if="unassignedReservations.length > 1">do</span>
            <span v-else>does</span>
            not have cars assigned to it. Please contact your fleet supervisor,
            who is in charge of updating the dispatch schedule.
          </p>
          <!-- Active Reservations -->
          <div class="table-responsive mt-3">
            <table class="table mb-3 table-striped">
              <thead class="border-none">
                <tr>
                  <th scope="col">#</th>
                  <th scolpe="col">Type</th>
                  <th scope="col">Vin</th>
                  <th scope="col">Platform ID</th>
                  <th scope="col">Cleaning</th>
                  <th scope="col">Status</th>
                  <th scope="col">Location</th>
                  <th scope="col">Time</th>
                  <th scope="col" v-if="isMasterSchedule == true">Assigned</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="_.isEmpty(groupedReservations)">
                  <td colspan="9" style="background-color: #f7f7f7">
                    No items have been added to your schedule.
                  </td>
                </tr>
              </tbody>
              <tbody>
                <!--<tr>
                  <td
                    colspan="8"
                    class="fw-bold"
                    style="background-color: #f7f7f7"
                  >
                    {{ vin }}
                  </td>
                </tr>-->

                <tr
                  v-for="reservation of filteredReservations"
                  class="mt-3"
                  :key="
                    'reservation-cleaning-' +
                    reservation.platform_id +
                    '-' +
                    reservation.type
                  "
                >
                  <td class="align-middle">
                    <span
                      class="badge badge-tertiary"
                      v-if="reservation.order != 1000"
                      >{{ reservation.order }}</span
                    >

                    <span v-else></span>
                  </td>

                  <td class="align-middle">
                    <span class="badge badge-tertiary">{{
                      reservation.type[0].toUpperCase()
                    }}</span>
                  </td>

                  <td class="align-middle">
                    {{ reservation.vehicle_vin.slice(-6) }}
                  </td>

                  <td class="align-middle">
                    {{ reservation.platform_id }}
                  </td>

                  <td class="align-middle">
                    <span
                      class="badge badge-tertiary"
                      v-if="reservation.action === true"
                      >Complete</span
                    >
                    <span
                      class="badge badge-primary"
                      v-if="reservation.action === false"
                      >Incomplete</span
                    >
                  </td>

                  <td
                    class="align-middle"
                    style="white-space: nowrap !important"
                  >
                    <span v-if="reservation.status == 'Pending'">Pending</span>
                    <span
                      v-if="
                        reservation.status == 'On Rent' &&
                        reservation.late == false
                      "
                      >On Rent</span
                    >
                    <span
                      v-if="
                        reservation.status == 'On Rent' &&
                        reservation.late == true
                      "
                      >On Rent (Late)</span
                    >
                    <span v-if="reservation.status == 'Canceled'"
                      >Canceled</span
                    >
                    <span
                      v-if="
                        reservation.status == 'Returned' &&
                        reservation.late == false
                      "
                      >Returned</span
                    >
                    <span
                      v-if="
                        reservation.status == 'Returned' &&
                        reservation.late == true
                      "
                      >Returned (Late)</span
                    >
                  </td>

                  <td class="align-middle">
                    {{
                      $store.state.user.locations.find((location) => {
                        return reservation.location_id == location.hq_id;
                      })
                        ? $store.state.user.locations.find((location) => {
                            return reservation.location_id == location.hq_id;
                          }).name
                        : ""
                    }}
                  </td>

                  <td class="align-middle">
                    {{
                      new Date(reservation.date).toLocaleDateString("en-US", {
                        timeZone: reservation.timezone,
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                    }}
                  </td>

                  <td
                    class="align-middle"
                    style="min-width: 25%"
                    v-if="isMasterSchedule == true"
                  >
                    {{
                      $store.state.user.employees.find((employee) => {
                        return employee.user_id == reservation.user_id;
                      })
                        ? $store.state.user.employees.find((employee) => {
                            return employee.user_id == reservation.user_id;
                          }).name.first_name +
                          " " +
                          $store.state.user.employees.find((employee) => {
                            return employee.user_id == reservation.user_id;
                          }).name.last_name
                        : ""
                    }}
                  </td>
                  <td class="align-middle">
                    <router-link
                      :to="
                        '/' +
                        reservation.type +
                        '/' +
                        reservation.platform_id.toLowerCase() +
                        '/0'
                      "
                      class="btn btn-primary btn-sm"
                      tag="button"
                      v-if="reservation.action === false"
                    >
                      Go
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 mb-5"></div>
      </div>
    </div>
    <!-- Filters -->
    <div
      class="modal fade"
      id="schedule-filters"
      tabindex="-1"
      aria-labelledby="filterModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1>
              <span class="highlighted">Filters.</span>
            </h1>
          </div>
          <div class="modal-body pt-0">
            <label>Date:</label>
            <select
              class="form-select mb-3"
              id="dateSelect"
              v-model="selected"
              @change="selectDate"
            >
              <option value="yesterday">Yesterday</option>
              <option value="today" selected>Today</option>
              <option value="tomorrow">Tomorrow</option>
            </select>
            <!-- Button Slider -->
            <label class="mt-3">Schedule Type:</label>
            <select
              class="form-select mb-3"
              id="changeSelect"
              v-model="isMasterSchedule"
            >
              <option :value="false" selected>My Schedule</option>
              <option :value="true">Master Schedule</option>
            </select>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ScheduleView",
  data: function () {
    return {
      selected: "today",
      selectedDate: new Date(),
      id: "",
      isMasterSchedule: false,
    };
  },
  methods: {
    selectDate: function () {
      let selectedDate;
      const today = new Date();
      switch (this.selected) {
        case "yesterday":
          selectedDate = new Date(today.setDate(today.getDate() - 1));
          break;
        case "tomorrow":
          selectedDate = new Date(today.setDate(today.getDate() + 1));
          break;
        default:
          selectedDate = today;
      }
      // Format selectedDate as needed and perform actions
      console.log(selectedDate); // For demonstration
      // You can emit this date to parent component or use it within this component
      this.selectedDate = selectedDate;
    },
    onAssignmentChange: function (event, reservation) {
      const newUser = event.target.value;
      this.updateAssignment(reservation, newUser);
    },
    updateAssignment: function (reservation, user_id) {
      const suffix = reservation.type == "pickup" ? "pickup" : "return";
      const url = this.$store.state.root_url + "/reservation/user/" + suffix;
      this.$axios
        .post(
          url,
          {
            id: reservation.id,
            user_id: user_id,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    vehicles() {
      return this.$store.state.user.vehicles;
    },
    reservations() {
      return this.$store.state.user.reservations;
    },
    sortedReturns: function () {
      if (this.$store.state.user.regions.length === 0) {
        return [];
      }
      let filteredReservations = this.reservations;

      filteredReservations = filteredReservations.filter((reservation) => {
        return reservation.status != "Canceled" && reservation.extended != true;
      });

      const getDateTimeInZone = (date, timeZone, startOrEnd) => {
        // Convert the input date to the specified time zone first
        const zoneDate = new Date(
          date.toLocaleString("en-US", { timeZone: timeZone })
        );

        // Adjust the hours, minutes, seconds, and milliseconds based on startOrEnd
        if (startOrEnd === "start") {
          zoneDate.setHours(0, 0, 0, 0); // Set to start of the day in the target time zone
        } else {
          zoneDate.setHours(23, 59, 59, 999); // Set to end of the day in the target time zone
        }

        return zoneDate;
      };

      const today = new Date(this.selectedDate);
      const timeZone = this.$store.state.user.regions.find((region) => {
        return region.id == this.$store.state.user.region;
      }).timezone;

      const todayStart = getDateTimeInZone(today, timeZone, "start");
      const todayEnd = getDateTimeInZone(today, timeZone, "end");

      filteredReservations = filteredReservations.filter((reservation) => {
        const dropoffDate = new Date(
          new Date(reservation.return_at).toLocaleDateString("en-US", {
            timeZone: reservation.return_timezone,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        );

        return dropoffDate >= todayStart && dropoffDate <= todayEnd;
      });

      return filteredReservations;
    },
    sortedPickups: function () {
      if (this.$store.state.user.regions.length === 0) {
        return [];
      }
      let filteredReservations = this.reservations;

      filteredReservations = filteredReservations.filter((reservation) => {
        return reservation.status != "Canceled" && reservation.extended != true;
      });

      const getDateTimeInZone = (date, timeZone, startOrEnd) => {
        // Convert the input date to the specified time zone first
        const zoneDate = new Date(
          date.toLocaleString("en-US", { timeZone: timeZone })
        );

        // Adjust the hours, minutes, seconds, and milliseconds based on startOrEnd
        if (startOrEnd === "start") {
          zoneDate.setHours(0, 0, 0, 0); // Set to start of the day in the target time zone
        } else {
          zoneDate.setHours(23, 59, 59, 999); // Set to end of the day in the target time zone
        }

        return zoneDate;
      };

      const today = new Date(this.selectedDate);
      const timeZone = this.$store.state.user.regions.find((region) => {
        return region.id == this.$store.state.user.region;
      }).timezone;

      const todayStart = getDateTimeInZone(today, timeZone, "start");
      const todayEnd = getDateTimeInZone(today, timeZone, "end");

      filteredReservations = filteredReservations.filter((reservation) => {
        const pickupDate = new Date(
          new Date(reservation.pickup_at).toLocaleDateString("en-US", {
            timeZone: reservation.pickup_timezone,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        );

        return pickupDate >= todayStart && pickupDate <= todayEnd;
      });

      return filteredReservations;
    },
    combinedSortedReservations: function () {
      // Step 1: Merge and mark each reservation as a pickup or return for later processing
      let combinedReservations = [
        ...this.sortedPickups.map((reservation) => ({
          ...reservation,
          type: "pickup",
          date: reservation.pickup_at,
          action: reservation.pickup_photos,
          location_id: reservation.pickup_location_id,
          timezone: reservation.pickup_timezone,
          user_id: reservation.pickup_user_id,
          order: reservation.pickup_schedule_priority,
        })),
        ...this.sortedReturns.map((reservation) => ({
          ...reservation,
          type: "return",
          date: reservation.return_at,
          action: reservation.return_photos,
          location_id: reservation.return_location_id,
          timezone: reservation.return_timezone,
          user_id: reservation.return_user_id,
          order: reservation.return_schedule_priority,
        })),
      ];

      /*// Step 2: Sort by date (assuming pickup_at for pickups and return_at for returns)
      combinedReservations.sort((a, b) => {
        let dateA =
          a.type === "pickup" ? new Date(a.pickup_at) : new Date(a.return_at);
        let dateB =
          b.type === "pickup" ? new Date(b.pickup_at) : new Date(b.return_at);
        return dateA - dateB;
      });*/

      // Step 2: Sort reservations
      combinedReservations.sort((a, b) => {
        // Check if a or b has order 1000
        if (a.order === 1000 && b.order === 1000) {
          // If both are 1000, sort by time
          return new Date(a.date) - new Date(b.date);
        } else if (a.order === 1000) {
          return 1; // a comes after b
        } else if (b.order === 1000) {
          return -1; // b comes after a
        } else {
          // If neither is 1000, sort by order (ascending)
          if (a.order !== b.order) {
            return a.order - b.order;
          }
          // If orders are equal, sort by time
          return new Date(a.date) - new Date(b.date);
        }
      });

      // Step 4: Group by vehicle_vin
      return combinedReservations;
    },
    filteredReservations: function () {
      let reservations = this.combinedSortedReservations;
      const v =
        this.isMasterSchedule == false
          ? // Step 3: Filter out null VINs before grouping
            reservations.filter(
              (reservation) =>
                reservation.vehicle_vin !== null &&
                reservation.vehicle_vin !== undefined &&
                reservation.user_id == this.$store.state.user.user_id
            )
          : // Step 3: Filter out null VINs before grouping
            reservations.filter(
              (reservation) =>
                reservation.vehicle_vin !== null &&
                reservation.vehicle_vin !== undefined
            );
      return v;
    },
    groupedReservations: function () {
      return this._.groupBy(this.filteredReservations, "vehicle_vin");
    },
    unassignedReservations: function () {
      // Step 3: Filter out null VINs before grouping
      return this.combinedSortedReservations.filter(
        (reservation) =>
          reservation.vehicle_vin == null ||
          reservation.vehicle_vin == undefined ||
          reservation.vehicle_vin == ""
      );
    },
  },
};
</script>
<style scoped>
#reservationOffcanvas-schedule {
  min-width: 75% !important;
}
.large-switch .form-check-input {
  cursor: pointer;
  /* Scale up the switch */
  transform: scale(1.5);
  /* Adjust margin to handle the increased size */
  margin-left: -1.25rem;
  margin-right: 0.5rem;
}
</style>
